import { ModalLayout, ModalContainer } from "./styled";

export default function Modal({ visible, children }) {
  return (
    <ModalLayout
      visible={visible}
      closable={false}
      centered
      footer={null}
      mask={true}
      style={{ maxWidth: 1000 }}
    >
      <ModalContainer>{children}</ModalContainer>
    </ModalLayout>
  );
}
