import * as gtag from "../../utils/gtag";

export const eventCallUs = () => {
  gtag.event({
    eventName: "eventGAPlayground",
    action: "phone number",
    category: "contact",
    label: "call us button",
  });
}

export const eventShowroomDirections = (showroom) => {
  gtag.event({
    eventName: "event_view_showroom_directions",
    action: "Clicks on showroom directions",
    category: "locations",
    label: showroom,
  });
}

export const eventClickPhoneNumber = (phone, action) => {
  gtag.event({
    eventName: "event_click_phone_number",
    action: action || "Clicks on phone numbers",
    category: "contact",
    label: phone || "866-642-7703",
  });
}

export const eventClickEmail = (email) => {
  gtag.event({
    eventName: "event_click_email",
    action: "Clicks on email address in footer",
    category: "contact",
    label: email || "appointment@classyclosets.com",
  });
}

export const eventFormSubmission = (formName, category) => {
  gtag.event({
    eventName: "event_generate_lead",
    action: "Submit form",
    category: category || "contact",
    label: formName || "Contact us Page",
  });
}

export const customGA4Event = ({ eventName }) => {
  gtag.event({
    eventName: eventName
  });
}