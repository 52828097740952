import styled, { css } from "styled-components";
import { CustomModal } from "../../SchedulePopup/styled";

export const ModalContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 10px solid ${theme.colors.darkGray2};

    @media only screen and (max-width: 768px) {
      height: 100%;
      width: 100%;
      transform: none;
      top: 0px;
      left: 0px;
    }
  `}
`;

export const ModalLayout = styled(CustomModal)`
  max-width: 1008px;
`;
